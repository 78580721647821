.movie-content {
  padding-top: 0;

  @include respond-to(tablet) {
    padding-top: $grid-gutter-width;
  }
}

.progressive-image img {
  transition: filter .5s;
}

.shrug {
  font-size: 50px;
  text-align: center;
}

.movies-wrapper {
  min-height: 200px;
}

.movie-container {
  transition: max-height ease .2s;
  max-height: 0;

  &.open {
    max-height: 15000px;
  }

  .title {
    margin-bottom: $grid-gutter-width;
  }

  .video-container {
    @include respond-to(tablet) {
      margin: 0;
      padding: 0 10px 20px;
    }
  }

  .video-element {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    .loader {
      display: block;
      opacity: 1;
    }

    .loader.loaded {
      display: none;
      opacity: 0;
    }

    .video {
      display: none;

      .loaded & {
        display: block;
      }
    }

    .video iframe {
      transition: all ease .3s;
      opacity: 0;

      .loaded & {
        opacity: 1;
      }
    }
  }

  .description {
    padding: $grid-gutter-width;
    border-bottom: dashed 1px $color-highlight;
    margin-bottom: $grid-gutter-width;

    @include respond-to(tablet) {
      padding: $grid-gutter-width 0;
    }
  }
}

.movies-container {
  overflow: hidden;
  list-style: none;
  margin-top: 0;
  padding: 0;

  .movie {
    width: 100%;

    @include respond-to(tablet) {
      float: left;
      width: 50%;
      padding: 0 $grid-gutter-width/2 $grid-gutter-width;
    }

    @include respond-to(web) {
      width: 100% * 1/3;
    }

    .meta-wrapper {
      transition: opacity ease .25s, background ease .25s;
      opacity: 0;
      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .meta {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      padding: 0 15px;

      @include respond-to(mobile-only) {
        top: 100%;
        transform: translateY(-100%);
        background: rgba(0,0,0,0.65);
        padding: 15px;
      }

      .title,
      .description {
        display: block;
      }

      .title {
        @include text-overflow(24px, 32px, 1);
        color: $color-red;
        font-weight: 500;
        &::before {
          content: '- '
        }
        &::after {
          content: ' -'
        }
      }

      .description {
        @include text-overflow(16px, 16px, 3);
        color: $color-white;
      }
    }

    &.highlight {
      width: 100%;

      .video-detail-view & {
        @include respond-to(web) {
          width: 100% * 1/3;
        }
      }

      .video-view & {
        .loaded .meta-wrapper {
          background: rgba($color-black, .5);
          opacity: 1;
        }
        .meta {
          @include respond-to(web) {
            transition: ease .3s;
            padding: 0 50px;
          }
        }
        .title {
          @include respond-to(web) {
            @include text-overflow(45px, 65px, 1);
            font-weight: bold;
            color: $color-white;
          }
        }
        .description {
          @include respond-to(web) {
            display: none;
            opacity: 0;
            transition: opacity ease .3s;
            @include text-overflow(24px, 25px, 5);
          }
        }

        &:hover {
          .meta-wrapper {
            background: rgba($color-black, .75);
          }
          .description {
            display: block;
            opacity: 1;
          }
        }
      }
    }

    .meta-wrapper {
      @include respond-to(mobile-only) {
        opacity: 1;
      }
    }

    &:hover {
      .meta-wrapper {
        @include respond-to(mobile) {
          background: rgba($color-black, .65);
          opacity: 1;
        }
      }
    }
  }
}

.video-detail-view .content-wrapper > .loader {
  display: none;
}
