// ------------------------------------
// BREAKPOINTS
// ------------------------------------
@mixin respond-to($device) {
  @if $device == mobile-only {
    @media (max-width: $grid-width-mobile) {
      @content;
    }
  } @else if $device == mobile {
    @media (min-width: $grid-width-mobile + 1) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: $grid-width-tablet + 1) {
      @content;
    }
  } @else if $device == tablet-only {
    @media (min-width: $grid-width-tablet + 1) and (max-width: $grid-width-web-small) {
      @content;
    }
  } @else if $device == tablet-down {
    @media (max-width: $grid-width-tablet + 1) {
      @content;
    }
  } @else if $device == web {
    @media (min-width: $grid-width-web + 1) {
      @content;
    }
  } @else if $device == small-web {
    @media (min-width: $grid-width-web-small + 1) {
      @content;
    }
  } @else if $device == web-only {
    @media (min-width: $grid-width-web + 1) and (max-width: $grid-width-big) {
      @content;
    }
  } @else if $device == small-web-down {
    @media (max-width: $grid-width-web-small) {
      @content;
    }
  } @else if $device == web-down {
    @media (max-width: $grid-width-web) {
      @content;
    }
  } @else if $device == big {
    @media (min-width: $grid-width-big + 1) {
      @content;
    }
  }
}

// ------------------------------------
// RETINA IMAGES
// ------------------------------------
@mixin image-retina {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    @content;
    zoom: 0.5;
  }
}

// ------------------------------------
// CLEARFIX
// ------------------------------------
@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// ------------------------------------
// CONTROL AMOUNT OF TEXT DISPLAYED
// ------------------------------------
@mixin text-overflow(
  $fontSize,
  $lineHeight,
  $lines
) {
  font-size: $fontSize;
  line-height: $lineHeight;
  height: $lineHeight * $lines;
  overflow: hidden;

  @if $lines == 1 {
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    text-overflow: inherit;
    white-space: normal;
  }
}
