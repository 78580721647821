// ------------------------------------
// VARIABLES FOR:
// ------------------------------------
// LAYOUT
// ------------------------------------
$body-gap: 30px;
$body-content-minHeight: 500px;
// ------------------------------------
// GRID
// ------------------------------------
$grid-width-mobile: 480px;
$grid-width-tablet: 768px;
$grid-width-web-small: 1024px;
$grid-width-web: 1100px;
$grid-width-big: 1100px;
$grid-margin: 100px;
$grid-padding-tablet: 15px;
$grid-gutter-width: 20px;
// ------------------------------------
// COLORS
// ------------------------------------
$color-white: rgb(255, 255, 255);
$color-almostWhite: rgb(248, 247, 248);
$color-black: rgb(0, 0, 0);
$color-lightenGray: rgb(241, 241, 241);
$color-lightGray: rgb(219, 219, 219);
$color-lowGray: rgb(210, 210, 210);
$color-gray: rgb(193, 193, 193);
$color-mediumLittleGray: rgb(161, 161, 161);
$color-mediumGray: rgb(131, 131, 131);
$color-mediumDarkGray: rgb(115, 115, 115);
$color-mediumDarkGrayest: rgb(102, 102, 102);
$color-darkGray: rgb(67, 67, 67);
$color-darkenGray: rgb(57, 57, 57);
$color-darkestGray: rgb(34, 34, 34);
$color-darkYellow: rgb(90, 78, 27);
$color-blue: rgb(52, 152, 219);
$color-lightBlue: rgb(188, 206, 255);
$color-darkBlue: rgb(41, 128, 185);
$color-darkenBlue: rgb(18, 34, 56);
$color-red: rgb(207,41,38);
$color-highlight: $color-red;
$color-companyRed: #FF3739;
$color-search: #E39D00;
// ------------------------------------
// ADDITIONALS
// ------------------------------------
$navbar-prefix: '|';
